import type { NavigationFailure, RouteLocationNamedRaw, RouteLocationPathRaw } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useUserStore } from '~/store/user-store'
import { useSelectionStore } from '~/store/selection-store'
import type { RouteLocationNormalized } from '#vue-router'

export default defineNuxtRouteMiddleware(async (to: RouteLocationNormalized): Promise<void | NavigationFailure | false | string | RouteLocationPathRaw | RouteLocationNamedRaw> => {
  // Otherwise the redirect is done on server side. Would be okay but there's
  // no store on the server since middlewares are loaded before plugins
  // and therefore payment status doesn't get set
  if (import.meta.client) {
    const userStore = useUserStore()
    const selectionStore = useSelectionStore()
    const { selectedStore } = storeToRefs(selectionStore)

    const redirectStatus = to.query.redirect_status as string

    // if payment intent with redirect statis is present redirect to success or error page
    if (redirectStatus) {
      switch (redirectStatus) {
        case 'succeeded':
          return navigateTo('/success')
        case 'failed':
          return navigateTo('/error')
      }
    }

    // Redirect to home if no user is logged in
    if (!userStore.user?.id) {
      return navigateTo('/')
    }
    // Get subscriptions for the user and redirect to home if error or no subscription is found
    const subscriptionResponse = await userStore.getSubscriptions()
    if (subscriptionResponse?.error) {
      return navigateTo('/')
    }
    // Redirect to home if found subscription is already active
    if (userStore.subscription?.status === 'active') {
      return navigateTo('/')
    }
    // Redirect to home and reset subscription and payment if no subscription is found
    if (!userStore.subscription?.id) {
      userStore.resetSubscriptionAndPayment()
      return navigateTo('/')
    }
    // Set store so the country config gets set and prices and times are displalyed in the correct timezone and currency
    selectedStore.value = userStore.subscription?.store
    // Create payment if no payment is found
    if (!userStore.payment?.id) {
      await userStore.createPayment()
    }
    // Create stripe publishable key if not found
    if (!userStore.stripePublishableKey && userStore.payment?.id) {
      await userStore.getStripePublishableKey()
    }
  }
})
